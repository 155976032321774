$color-medium-blue: #306ba8;
$color-mid-blue-two: #2a6baa;

.ingenico-loader {
  text-align: center;
  padding: 30px;
  img {
    width: 75px;
    height: 75px;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 1000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.ingenico-return {
  .message {
    text-align: center;
  }

  .ingenico-button {
    height: 50px;
    padding: 0 27px;
    background: $color-mid-blue-two;
    border-radius: 30px;
    text-align: center;
    line-height: 50px;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    /* float: left; */
    border: 0;
    &.disabled {
      background: #989898;
    }
    &.connect-existing {
      float: initial;
      margin-top: 30px;
    }
  }

  .ingenico-payment-warning {
    font-family: Open Sans, sans-serif;
    width: 100%;
    min-height: 130px;
    background-color: #fdeaeb;
    padding-top: 15px;
    text-align: center;
  }

  .ingenico-info {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
}

.ingenico-confirmation {
  li {
    border: solid 1px #dddddd;
    max-width: 695px;
    margin-bottom: 16px;
    cursor: pointer;
    h2 {
      margin-bottom: 16px;
    }
    .payment-logo {
      position: relative;
      display: flex;
      padding: 16px;
      img {
        float: left;
        max-width: 50px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 10px;
      }
      h3 {
        float: left;
        line-height: 28px;
        padding-left: 70px;
        margin-bottom: 0;
      }
    }
    .cc-logo {
      display: inline-block;
      padding: 16px;

      img {
        float: left;
        height: 50px;
        max-width: 100px;
        margin-left: 5px;
      }
    }
    a {
      color: #000000;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  iframe {
    width: 100%;
    padding-left: 70px;
    height: 360px;
    border: 0;
    display: none;
  }
}
#ingenico-payment-form {
  img {
    padding: 6px;
  }
}
#ingenico-error {
  p {
    text-align: center;
    background: #fde9eb;
    padding: 20px;
    color: #000;
    margin: 0;
  }
}
table.ncoltable3 {
  min-height: 170px;
}
@-ms-keyframes spin {
  from { -ms-transform: rotate(0deg); }
  to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}